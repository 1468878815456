// extracted by mini-css-extract-plugin
export var needahand = "index-module--needahand--03138";
export var mobileapp = "index-module--mobileapp--b2474";
export var mobileappcontent = "index-module--mobileappcontent--96328";
export var quadrant = "index-module--quadrant--8e1aa";
export var sectiontitle = "index-module--sectiontitle--3e8ef";
export var sectiontitlemultiline = "index-module--sectiontitlemultiline--00c2a";
export var sectiontitlebottom = "index-module--sectiontitlebottom--78785";
export var registeredicon = "index-module--registeredicon--80fba";
export var quadranttitle = "index-module--quadranttitle--4cdaf";
export var quadrantlinksection = "index-module--quadrantlinksection--b72dd";
export var needahandlinksection = "index-module--needahandlinksection--fec0c";
export var mobileappimage = "index-module--mobileappimage--bdbb1";
export var quadrantlinksectionrow = "index-module--quadrantlinksectionrow--f8050";
export var mobileappcontentarea = "index-module--mobileappcontentarea--32a26";
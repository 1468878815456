import React, { useState } from 'react'
import * as styles from './style.module.css'
import {Link} from 'gatsby'
import {
	CarouselProvider,
	Dot,
	Slide,
	Slider,
	ButtonBack,
	ButtonNext,
	Image
} from "pure-react-carousel"
import 'pure-react-carousel/dist/react-carousel.es.css'

import arrowleft from "../../images/home/arrow-left.png"
import arrowright from "../../images/home/arrow-right.png"
import image_button from "./buttonplaceholder.png"


const SlideShow = ({sliderlist, autoplayinterval, showloading, showDots, imgwdhtratio, leftArrowImg, rightArrowImg, itemcount, clickfunc, customSlideClass, className, usemobilelayout, children})=> {
	const [moving, setMoving] = useState(false); //for disabling automatic animation while user is navigating slideshow so that they won't double jump

	const disableAnimation = () => {
		if (!moving) {
			setMoving(!moving);
			setTimeout( ( ()=>{setMoving(!moving);} ), 2000)
		}
	}

	if (typeof imgwdhtratio === "undefined" || typeof sliderlist === "undefined") {
		return <></>
	}

	const displayitemcount = typeof itemcount === "undefined" ? 1: itemcount;
	const hasjumpbuttons = typeof showDots === "undefined"? true: showDots;
	const autoplaymode = typeof autoplayinterval === "undefined" ? true: autoplayinterval>0;
	const mobilemode = typeof usemobilelayout === "undefined" ? true : usemobilelayout;
	return <div className={mobilemode ? styles.slidetall:styles.slidewide}>
				<CarouselProvider
					naturalSlideWidth={imgwdhtratio}
					naturalSlideHeight={1}
					totalSlides={sliderlist.length}
					isPlaying={!moving && autoplaymode}
					interval={typeof autoplayinterval === "undefined" ? 5000: autoplayinterval}
					hasMasterSpinner={typeof showloading === "undefined" ? false: showloading}
					className={styles.container}
					infinite={true}
					visibleSlides={displayitemcount}
				>
					<Slider className={className}>
						{sliderlist.map((curslide, slideindex) =>
							<Slide className={customSlideClass} index={slideindex} >
								{
									typeof clickfunc !== "undefined" ?
										<Image src={curslide.imgsrc}  srcset={curslide.hasOwnProperty("srcset")?curslide.imgsrcset:""} isBgImage={true} className={styles.slideclickable} onClick={()=>{
											clickfunc({slideindex})
										}}  />
									:
										<Image src={curslide.imgsrc} srcset={curslide.hasOwnProperty("srcset")?curslide.imgsrcset:""} isBgImage={true}  />
								}
								{curslide.hasOwnProperty("content") && <>
									{curslide.content !== null && <>
										<div class={styles.slidecontent}>
											{ curslide.content.hasOwnProperty("title") &&
												<div class={`${styles.slidetitle} font-size-large font-style-title`} dangerouslySetInnerHTML={{__html:curslide.content.title}}/>
											}
											{ curslide.content.hasOwnProperty("subtitle") &&
												<div class={`${styles.slidesubtitle} font-size-medium`}>{curslide.content.subtitle}</div>
											}
											{ (curslide.content.hasOwnProperty("button") && curslide.content.hasOwnProperty("buttonurl")) &&
												<Link to={curslide.content.buttonurl} className={`${styles.slidebutton} font-size-medium color-background-primary`}>
													{curslide.content.button}
												</Link>
											}
										</div>
										{curslide.content.hasOwnProperty("clickUrl") &&
											<a href={curslide.content.clickUrl} className={`${styles.slideurl}`}>
												&nbsp;
											</a>
										}
										{curslide.content.hasOwnProperty("clickNav") &&
											<Link to={curslide.content.clickNav} className={`${styles.slideurl}`}>
												&nbsp;
											</Link>
										}
									</>}
								</>}
							</Slide>
						)}
					</Slider>
					{
						displayitemcount < sliderlist.length &&
						<>
							<ButtonBack
								className={`${styles.buttonBack} ${styles.button}`}
								onClick={disableAnimation}
							>
								<img src={typeof leftArrowImg === "undefined"? arrowleft : leftArrowImg} alt='<'/>
							</ButtonBack>
							<ButtonNext
								className={`${styles.buttonNext} ${styles.button}`}
								onClick={disableAnimation}
							>
								<img src={typeof rightArrowImg === "undefined"? arrowright : rightArrowImg} alt='>'/>
							</ButtonNext>
						</>
					}
					{
						hasjumpbuttons &&
						<div
							className={`${styles.dotGroup}`}
						>
							{sliderlist.map((data, index) =>
								<Dot slide={index}>
									<img className={styles.buttonplaceholder} src={image_button} alt=''/>
								</Dot>
							)}

							{children?children:null}
						</div>
					}
					{
						/* childrenAsDot === true && */ (children ?children:null)
					}
				</CarouselProvider>
			</div>
}

export default SlideShow
//// Sample Page
/*
import React, {useState} from "react"
import Layout from "../components/layout"
import SlideShow from "../components/slideshow"

const desktopslideimages = [
	{"imgsrc":"/img/home/desktop-slider-1.jpg"},
	{"imgsrc":"/img/home/desktop-slider-2.jpg"}
];

const mobileslideimages = [
	{"imgsrc":"/img/home/mobile-slider-1.jpg"},
	{"imgsrc":"/img/home/mobile-slider-2.jpg"}
];


const SampleSlideshowPage = () => {
	const mobilewdhtratio = 1104/1545
	const desktopwdhtratio = 2048/1545

	const [imgwdhtratio, setImgwdhtratio] = useState(mobilewdhtratio);

	const resizeHandler = (newtype) => {
		if (newtype < 2) {
			setImgwdhtratio(desktopwdhtratio);
		} else {
			setImgwdhtratio(mobilewdhtratio);
		}
	};

	const clickfunc = (slideobj) => {
		console.log(slideobj);
	}

	return (
			<Layout activePath='' pageTitle="Sample SlideShow" resizeHandler={resizeHandler} >
				<SlideShow
					clickfunc={clickfunc}
					usemobilelayout={imgwdhtratio===mobilewdhtratio}
					imgwdhtratio={imgwdhtratio}
					sliderlist={imgwdhtratio===desktopwdhtratio ? desktopslideimages : mobileslideimages} />
			</Layout>
		)

}
export default SampleSlideshowPage;

*/
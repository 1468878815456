// extracted by mini-css-extract-plugin
export var dotGroup = "style-module--dotGroup--97547";
export var container = "style-module--container--aa853";
export var slidecontent = "style-module--slidecontent--00a0a";
export var slideclickable = "style-module--slideclickable--a845b";
export var slidetitle = "style-module--slidetitle--c355f";
export var slidesubtitle = "style-module--slidesubtitle--8b3b9";
export var slidebutton = "style-module--slidebutton--c5beb";
export var buttonNext = "style-module--buttonNext--c6c19";
export var buttonBack = "style-module--buttonBack--48a9b";
export var button = "style-module--button--5a729";
export var buttonplaceholder = "style-module--buttonplaceholder--4fb0b";
export var slidetall = "style-module--slidetall--851f7";
export var slidewide = "style-module--slidewide--76625";
export var slideurl = "style-module--slideurl--4e608";